.NavbarItems {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  width: 98%;
  height: 60px;
  border-radius: 30px;
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translate(-50%);
  z-index: 9999;
}

.Navbar-logo {
  margin-top: 8px;
  width: 56%;
  height: 56%;
}

.navbar-menu {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 30px;
  list-style: none;
  align-items: center;
}

.nav-title{
  list-style: none;
}

.nav-links {
  text-decoration: none;
  text-align: center;
  color: #C2B280;
  font-size: 1.2rem;
  font-weight: 200;
  padding: 0.7rem 1rem;
  white-space: nowrap;
}

.nav-links i {
  padding-right: px;
}

.nav-links:hover {
  background-color: #7cb5ec;
  color: white;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}

.menu-icons {
  display: none;
}

@media screen and (max-width: 850px) {
  .NavbarItems {
    z-index: 99;
  }
  .navbar-menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: #fff;
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: -100%;
    opacity: 0;
    align-items: stretch;
    padding: 80px 0 30px 0;
    margin: 0;
    transition: 0.3s ease-in-out;
    border-radius: 30px;
  }
  .navbar-menu.active {
    left: 0;
    opacity: 1;
    z-index: -1;
    transition: 0.3s ease-in-out;
  }
  .nav-links {
    display: block;
    width: 100%;
    font-size: 1.2rem;
    padding: 2rem 0;
  }
  .nav-links:hover {
    background: #7cb5ec;
    transition: none;
  }
  .menu-icons {
    display: block;
    cursor: pointer;
  }
  .menu-icons i {
    font-size: 1.2rem;
    color: #7cb5ec;
  }
}
