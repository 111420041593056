.portopolio {
  margin: 6rem 6rem;
}

.portopolio h2 {
  font-size: 2.5rem;
}

.portopolio p {
  text-align: justify;
}

.portopolio-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4.5rem;
}

.portopolio-container-reverse{
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  margin-top: 4.5rem;
}

.portopolio-content {
  width: 45%;
  text-align: start;
  font-size: 1.1rem;
}

.portopolio-content h2 {
  padding-bottom: 1rem;
}

.portopolio-image {
  position: relative;
  width: 50%;
  display: flex;
  justify-content: space-between;
  z-index: -99;
}

.portopolio-image img {
  width: 49%;
  height: 350px;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: -1px 1px 62px -18px rgba(0, 0, 0, 0.19);
}

.portopolio-image img:nth-child(2) {
  position: absolute;
  top: -10%;
  right: 0;
}

.text_portopolio {
  margin-top: 0.5rem;
}

@media screen and (max-width: 850px) {
  .portopolio {
    margin: 4rem 2rem;
  }
  .portopolio-container {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 2.5rem;
  }
  .portopolio-container-reverse{
    display: flex;
    flex-direction: column-reverse;
    margin-top: 4.5rem;
  }
  .portopolio-content {
    width: 100%;
  }
  .portopolio-image {
    width: 100%;
    margin: 1.8rem 0;
  }
  .portopolio-image img {
    height: 250px;
  }
}
