.hero {
  width: 100%;
  height: 100vh;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.hero-text {
  position: absolute;
  color: #fff;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.hero-text h1 {
  font-size: 2rem;
  font-weight: 800;
  background: #fff;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-text a {
  font-size: 1.5rem;
  font-weight: 800;
  background: #fff;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-text p {
  font-size: 1.2rem;
  color: #fff;
  padding: 0.5rem 0 0.2rem 0;
}

.hero-text .show{
  text-decoration: none;
  font-size: 1.2rem;
  background: #fff;
  padding: 1rem 2rem;
  border-radius: 15px;
  font-weight: bold;
  letter-spacing: 2px;
  color: #222;
}

.hero-about {
  width: 100%;
  height: 100vh;
  position: relative;
}

.hero-about h1{
  font-size: 4rem;
  font-weight: 800;
  background: #fff;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}


@media screen and (max-width: 850px) {
  .hero-text h1 {
    font-size: 1.5rem;
    font-weight: 800;
    background: #fff;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .hero-text p {
    font-size: 0.8rem;
    color: #fff;
    padding: 0.5rem 0 0.2rem 0;
  }

  .hero-text a {
    font-size: 0.8rem;
    font-weight: 800;
    background: #fff;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .hero-text  .show{
    text-decoration: none;
    font-size: 1rem;
    background: #fff;
    padding: 1rem 2rem;
    border-radius: 15px;
    font-weight: bold;
    letter-spacing: 2px;
    color: #222;
  }
}
