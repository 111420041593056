.AboutUs-container{
    margin: 4rem 6rem;
    text-align: justify;
}

.AboutUs-container h1{
    padding-bottom: 1rem;
}

.AboutUs-container p{
    padding-bottom: 2rem;
}

@media screen and (max-width:850px) {
    .AboutUs-container{
        margin: 4rem 2rem;
    }
}
