.Footer{
    padding: 4rem 6rem;
    background: #000000;
    color: #ffff;
}

.Footer-top {
    display: flex;
    align-items: center;
    text-align: start;
    justify-content: space-between;
    flex-wrap: wrap;
}

.Footer-left {
    width: 50%;
}

.Footer-copyright{
    margin-top: 50px;
}

.Footer-top i{
    color: #ffffff;
    font-size: 2rem;
    margin-left: 1rem;
}

.Footer-top i:hover{
    color: #01959a;
}

.Footer-bottom {
    width: 60%;
    padding-top: 2rem;
    text-align: start;
    display: flex;
    justify-content: space-between  ;
    flex-wrap: wrap;
}

.Footer-bottom div{
    display: flex;
    flex-direction: column;
}

.Footer-bottom h3{
    font-size: 1.8rem;
    padding: 1rem 0 .8rem 0;
}

.Footer-bottom a{
    text-decoration: none;
    color: #ffffff;
    padding-bottom: 0.4rem;
    font-size: 1.1rem;
}

@media screen and (max-width: 850px) {
    .Footer{
        padding: 4rem 2rem;
    }

    .Footer i{
        max-width: 1rem 1rem 0 0;
    }

    .Footer-bottom {
        width: 100%;
        padding-top: 2rem;
        text-align: start;
        display: flex;
        justify-content: space-between  ;
        flex-wrap: wrap;
    }

    .Footer-bottom div{
        width: 50%;
    }
    
    .Footer-left {
        width: 10%;
    }
}